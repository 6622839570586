import * as React from "react"
import Layout from "../../../../components/layout";
import Seo from "../../../../components/seo";
import * as styles from "./index.module.css"

const HelpShoppingDatasafetyPage = () => {
    return (
        <Layout isTop={false}>
            <Seo title="データの削除方法"
                 description="「共有できる買い物リスト」のアカウントを削除する方法はこちらでご覧いただけます。"/>
            <div className={styles.content}>
                <h2>「共有できる買い物リスト」のアカウント削除方法</h2>
                <p>
                    nullhouseが開発した<a href="https://play.google.com/store/apps/details?id=info.nullhouse.shopping">共有できる買い物リスト 家族やパートナーとメモを共有</a>のアカウント削除方法について、説明します。
                </p>

                <h3 className={styles.sectionTitle}>アカウントの削除方法</h3>
                <ol className={styles.decimalList}>
                    <li>アプリを起動します。</li>
                    <li>ホーム画面の左上の歯車アイコンをタップします。</li>
                    <li>一番したの「退会する」リンクをタップします。</li>
                    <li>退会ページで「退会する」ボタンをタップします。</li>
                </ol>

                <h3 className={styles.sectionTitle}>削除されるデータ</h3>
                <ol className={styles.discList}>
                    <li>ユーザー情報（ニックネーム）</li>
                    <li>登録した商品</li>
                    <li>所属しているグループ（他に所属しているメンバーがいる場合は削除されません）</li>
                    <li>通知に必要なFCMトークン</li>
                </ol>

                <h3 className={styles.sectionTitle}>データ保持期間について</h3>
                <ol className={styles.discList}>
                    <li>ユーザー情報（ニックネーム）: ユーザーが退会するまで</li>
                    <li>登録した商品: ユーザーが退会するまで、もしくはユーザーが商品をタップして消すまで。</li>
                    <li>所属しているグループ（所属しているユーザーが全員退会、もしくは他のユーザーにグループから退出されるまで）</li>
                    <li>通知に必要なFCMトークン: ユーザーが退会するまで</li>
                </ol>
            </div>
        </Layout>
    )
}

export default HelpShoppingDatasafetyPage
